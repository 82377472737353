@import "../global";
@import "global";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


#utoo-body {
    height: 100vh;
    background-image: url("/img/backend/user/12.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
